// i18next-extract-mark-ns-start products-engdyn-crankshaft

import { ENGDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductEngdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							ENGDYN provides two methods of solution for predicting
							crankshaft durability, incorporating both a classical
							approach using simple beam theory as well as a more
							advanced finite element (FE) approach for more
							definitive studies. ENGDYN calculates both the
							quasi-static and vibratory loads on the crankshaft, and
							automatically sets up the necessary FE analysis. With
							its inbuilt fatigue module, ENGDYN can also provide both
							multi-axial and Goodman durability calculations for the
							key design areas on the crankshaft.
						</p>
						<h2>Specific crankshaft applications</h2>
						<ul>
							<li>
								Crankshaft stress, fatigue and durability analysis
							</li>
						</ul>
						<h2>Key features</h2>
						<ul>
							<li>Concept-level and fully 3D FEA solutions</li>
							<li>Automatic loading of FE models for solution</li>
							<li>Additional external loads</li>
							<li>Separation of static and vibration loading</li>
							<li>
								Effect of surface treatment on material strength
							</li>
							<li>
								Stress concentration and fatigue notch factors
								considered
							</li>
							<li>
								Uni-axial and multi-axial fatigue algorithms
								included
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{ENGDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductEngdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-engdyn-crankshaft", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/engdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;
