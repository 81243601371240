import classNames from 'classnames';
import React from 'react';

const Aside = ({ children }: { children: React.ReactNode }) => {
	return (
		<aside className="col xl5 m12 m-spacer page__aside">{children}</aside>
	);
};

const Widget = ({
	title,
	children,
	className = '',
}: {
	title?: string;
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<div
			className={classNames('widget', {
				[className]: !!className,
			})}>
			<div className="widget__content">
				{title && <span className="widget__title">{title}</span>}
				{children}
			</div>
		</div>
	);
};

Aside.Widget = Widget;

export default Aside;
