import React from 'react';

const AssetList = ({ children }: { children: React.ReactNode[] }) => {
	return (
		<ul className="assets__list">
			{children.map((child, i) => (
				<li key={i} className="assets__item">
					{child}
				</li>
			))}
		</ul>
	);
};

export default AssetList;
